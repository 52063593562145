import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { UtilityService } from '../../../services/utility.service';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { MessagesService } from '../../../services/message.service';
@Component({
  selector: 'app-non-guided-booking',
  standalone: true,
  imports: [
    InputTextareaModule,
    FormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    InputNumberModule,
    ButtonModule,
    CheckboxModule
  ],
  templateUrl: './non-guided-booking.component.html',
  styleUrl: './non-guided-booking.component.css'
})
export class NonGuidedBookingComponent implements OnInit {
  @Input() prevCallback!: EventEmitter<any>;
  checked: boolean = true;
  data:any = {
    description: "",
    noteForCleaner: "",
    serviceType: "abstract",
    serviceTimeInMin: 0,
    count: 1
  };

  serviceTimeHrs: number = 0;
  public serviceData: any[] = [];

  constructor(
    private _common: CommonService,
    private _util: UtilityService
  ){}

  ngOnInit(): void {

    if (this._common.serviceRequest.serviceDetails) {
      this.serviceTimeHrs = this._common.serviceRequest.serviceDetails[0].serviceTimeInMin / 60;
      this.data = this._common.serviceRequest.serviceDetails[0];
      this.serviceData = this._common.serviceRequest.serviceDetails;
      this.serviceData.shift();
    } else {
      this._common.getData().subscribe({
        next: (data) => {
          this.serviceData = [];
          if (data && data.length) {
            for (let item of data) {
              if (item.serviceType === "non-guided") {
                this.serviceData.push(JSON.parse(JSON.stringify(item)));
              }
            }
          }
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
        complete: () => {
          // Handle completion
        },
      });
    }

  }

  public onSaveData():void {
    this.data.serviceTimeInMin = this.serviceTimeHrs * 60;
    this._common.serviceTimeInHours = this.serviceTimeHrs;
    this._common.serviceRequest.serviceDetails = [this.data,...this.serviceData];
  }

  public toggleService(index: number): void {
    if(this.serviceData[index].count === 0) {
      this.serviceData[index].count++;
    }else{
      this.serviceData[index].count--;
    }
  }
  public isServiceChecked(index: number): boolean {
    return this.serviceData[index].count > 0;
  }

  public showWarning():void{
    this._util.showMessage(
      RequestMapperService.WARN,
      MessagesService.MESSAGE_WARNING,
      MessagesService.NO_PROVIDERS_SELECTED
    );
  }
}
