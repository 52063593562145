<section class="non-guided-section">
    <div class="container">
        <div class="grid">
            <div class="col-12 md:col-0 lg:col-4">
                <figure class="m-0 ">
                    <img class="w-full h-full non-guided-img" src="/assets/img/non_guided.png" alt="non_guided" srcset="">
                </figure>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="flex flex-column gap-3">
                    <h6 class=" text-lg font-semibold md:white-space-nowrap m-0">Select areas to be cleaned</h6>
                    <div class=" flex flex-column gap-3 bg-white w-full px-3 py-3 h-29rem overflow-y-scroll area-select-section">
                       @for (service of serviceData; track $index) {
                        <div class="select1" [class.active]="service.count === 1" (click)="toggleService($index)">
                          <span>{{service.serviceName}}</span>
                          <p-checkbox
                             [ngModel]="isServiceChecked($index)"
                             [binary]="true">
                           </p-checkbox>
                          </div>
                       }

                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 lg:col-4">
                <div class="flex flex-column gap-3">
                    <span>
                        <h6 class=" text-lg font-semibold md:white-space-nowrap">Please describe your requirements here</h6>
                    <textarea class="w-full pb-0 text-area  lg:h-fit" rows="6" cols="30" pInputTextarea [(ngModel)]="data.description"></textarea>
                    </span>
                    <span>
                        <h6 class=" text-lg font-semibold">Note for the cleaner</h6>
                        <textarea class="w-full text-area lg:h-fit" rows="5" cols="30"  pInputTextarea [(ngModel)]="data.noteForCleaner"></textarea>
                    </span>
                    <span>
                        <h6 class="mb-2 text-lg font-semibold">Estimated Hours <span style="color: #e24c4c;">**</span></h6>
                        <p-inputGroup>
                            <p-inputNumber class="w-full text-xl" type="number" [(ngModel)]="serviceTimeHrs" pInputText placeholder="Estimated Hours" inputId="minmaxfraction"
                            mode="decimal"
                            [minFractionDigits]="0"
                            [maxFractionDigits]="1"
                            [min]="1"/>
                            <button type="button" class="w-5 border-none text-lg font-semibold" style="background: var(--bcd-btn); color: var(--primary-color-text);" pButton label="Hrs."></button>
                        </p-inputGroup>
                        @if (serviceTimeHrs>9) {
                          <small style="color: #e24c4c; margin-bottom: -1rem;">Service time cannot exceed 9 hours.</small>
                        }
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
