import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { CommonService } from '../../../services/common.service';
import { TransmissionService } from '../../../transmission/transmission.service';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { DOMAIN } from '../../../transmission/domainTypes';
import { UtilityService } from '../../../services/utility.service';
import { MessagesService } from '../../../services/message.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-guided-booking',
  standalone: true,
  imports: [DividerModule, CommonModule],
  templateUrl: './guided-booking.component.html',
  styleUrl: './guided-booking.component.css',
})
export class GuidedBookingComponent implements OnInit {
  public serviceData: any[] = [];
  public selectedServiceList: any[] = [];
  public serviceTimeInHours: number = 0;
  @Input() prevCallback!: EventEmitter<any>;

  constructor(
    private _transmit: TransmissionService,
    private _util: UtilityService,
    private _common: CommonService
  ) {
    this.calculateServiceTimeInHours();
  }

  async ngOnInit(): Promise<void> {
    if (this._common.serviceRequest.serviceDetails) {
      this.serviceData = this._common.serviceRequest.serviceDetails;
    } else {
      this._common.getData().subscribe({
        next: (data) => {
          this.serviceData = JSON.parse(JSON.stringify(data));
        },
        error: (error) => {
          console.error('Error fetching data:', error);
        },
        complete: () => {
          // Handle completion
        },
      });
    }

    this.calculateServiceTimeInHours();
  }

  public bookedCount(index: number, countType: string): void {
    if (countType === 'dec') {
      if (this.serviceData[index].count > 0) {
        this.serviceData[index].count--;
        this.serviceTimeInHours -=
          this.serviceData[index].serviceTimeInMin / 60;
        this.serviceTimeInHours =
          Math.round(this.serviceTimeInHours * 100) / 100;
      }
    } else if (countType === 'inc') {
      this.serviceData[index].count++;
      this.serviceTimeInHours += this.serviceData[index].serviceTimeInMin / 60;
      this.serviceTimeInHours = Math.round(this.serviceTimeInHours * 100) / 100;
    }
  }

  public getExtraItems(item: string, index: number): void {
    if (this.serviceData[index].count === 0) {
      this.serviceData[index].count = 1;
      this.serviceTimeInHours += this.serviceData[index].serviceTimeInMin / 60;
      this.serviceTimeInHours = Math.round(this.serviceTimeInHours * 100) / 100;
    } else {
      this.serviceData[index].count = 0;
      this.serviceTimeInHours -= this.serviceData[index].serviceTimeInMin / 60;
      this.serviceTimeInHours = Math.round(this.serviceTimeInHours * 100) / 100;
    }
  }

  public calculateServiceTimeInHours(): void {
    let totalMinutes = 0;
    this.serviceData?.forEach((service: any) => {
      totalMinutes += service.serviceTimeInMin * service.count;
    });
    this.serviceTimeInHours = totalMinutes / 60;
    this.serviceTimeInHours = Math.round(this.serviceTimeInHours * 100) / 100;
  }

  public onSaveData(): void {
    this._common.serviceRequest.serviceDetails = this.serviceData;
    this._common.serviceTimeInHours = this.serviceTimeInHours;
  }
}
