<section class="md:pt-3">
  <div class="container">
      <div class="grid summary-resp">
          <div class="col-12 lg:col-4 md:col-12 summary-data">
              <h6 class=" text-xl font-semibold">Summary</h6>
              @if (routeToGuided) {
                <div class="summay-section">
                    <span class="flex justify-content-between align-items-center font-medium text-lg p-3">
                      Service Details
                        <i class="material-icons cursor-pointer" style="color: var(--bcd-btn);cursor: pointer;" (click)="prevCallback.emit();">edit</i>
                    </span>
                    <ul class="px-3">
                        @for (item of serviceDetails; track item; let index = $index){
                          @if (item.serviceType === 'general' && item.count > 0){
                            <li>
                              @if(item.serviceName === 'Bedrooms'){
                                <i class="material-icons" style="color: var(--bcd-btn);">bed</i>
                              }@else if(item.serviceName === 'Bathrooms'){
                                <i class="material-icons" style="color: var(--bcd-btn);">bathtub</i>
                              }@else if(item.serviceName === 'kitchen'){
                                <i class="material-icons" style="color: var(--bcd-btn);">restaurant</i>
                              }@else {
                                <i class="material-icons" style="color: var(--bcd-btn);">meeting_room</i>
                              }
                              {{item.count}} {{item.serviceName | titlecase }}
                          </li>
                          }
                        }
                    </ul>

                    <h6 class="px-3 pt-3 font-semibold text-xl gap-2" style="color: var(--bcd-btn);display: grid;">
                        Added Areas
                        @for(item of serviceDetails; track item;){
                          @if (item.serviceType === 'extra' && item.count > 0){
                            <small class="text-base line-height-2 font-medium pb-2" style="color: var(--bcd-textBlack);">{{item.serviceName}}</small>
                          }
                        }

                    </h6>
                </div>
              } @else {
                <div class="nonguided-summay-section">
                    <span class="flex justify-content-between align-items-center font-medium text-lg p-3 border-bottom-2 " style="border-color: var(--bcd-border);">
                        Description Details
                        <i class="material-icons" style="color: var(--bcd-btn);cursor: pointer;" (click)="prevCallback.emit();">edit</i>
                    </span>
                    <ul class="overflow-y-scroll">
                        <li>
                          <h6 class="text-xl font-semibold text-left m-0">Selected areas to be cleaned</h6>
                          <p class="m-0  gap-2 grid-view">
                            @for (item of serviceDetails; track item; let index = $index){
                              @if (item.serviceType === 'non-guided' && item.count > 0){
                                <span class="selcted-box">{{item.serviceName}}</span>
                              }
                            }
                          <!-- <span class="selcted-box">Outdoor Kitchen</span>
                          <span class="selcted-box">Office Room</span>
                          <span class="selcted-box">Lawn</span>  -->

                          </p>
                        </li>
                        <li>
                            <h6 class="text-xl font-semibold text-left m-0">Requirement Details</h6>
                            <span class="text-base line-height-3" style="color: #98a4bc;">{{serviceDetails[0].description}}</span>
                        </li>
                        <li>
                            <h6 class="text-xl font-semibold text-left m-0">Note For The Cleaner</h6>
                            <span class="text-base line-height-3" style="color: #98a4bc;">{{serviceDetails[0].noteForCleaner}}</span>
                        </li>
                        <li>
                            <h6 class="text-xl font-semibold text-left m-0">Estimated Hours</h6>
                            <span class="text-base line-height-3" style="color: #98a4bc;">{{serviceDetails[0].serviceTimeInMin / 60}}</span>
                        </li>
                    </ul>
                </div>
              }
          </div>
          <div class="col-12 lg:col-4 md:col-12 date-range">
              <h6  class=" text-xl font-semibold text-left">Select Date</h6>
              <div class=" p-0 flex justify-content-start date-select">
                  <p-calendar class="max-w-full w-full calendar-show lg:h-30rem" (onSelect)="onDateSelect($event)" [(ngModel)]="date" [inline]="true" [minDate]="minDate" [maxDate]="maxDate"></p-calendar>
              </div>
          </div>
          <div class="col-12 lg:col-4 md:col-12 arrive-slot">
              <h6 class=" text-xl font-semibold text-left">Select Start Time</h6>
              <span class="text-base font-medium" style="color: #6E6E6E">{{ formatDate(date) }}</span>
              <!-- <ul class="time-slot-section" [ngClass]="{'disabled-time-slots': date === undefined}">
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot1)" [class.active]="activeSlot === slot1" #slot1>8:30AM - 11:30AM</li>
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot2)" [class.active]="activeSlot === slot2" #slot2>9:00AM - 1:15PM</li>
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot3)" [class.active]="activeSlot === slot3" #slot3>9:30AM - 12:30PM</li>
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot4)" [class.active]="activeSlot === slot4" #slot4>1:00PM - 3:10PM</li>
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot5)" [class.active]="activeSlot === slot5" #slot5>4:00PM - 5:00PM</li>
                <li class="text-base font-medium cursor-pointer" disabled="isTimeOver" (click)="date!== undefined && setActive(slot6)" [class.active]="activeSlot === slot6" #slot6>6:00PM - 8:00PM</li>
              </ul> -->

              
              
              <!-- <ul class="time-slot-section" [ngClass]="{'disabled-time-slots': date === undefined}">
                @for(time of timeSlotMapping; track time; let index = $index){
                  <li [ngClass]="{'disabled-time-slots-li': time.isTimeOver, 'text-base font-medium cursor-pointer': !time.isTimeOver}"
                  (click)="!time.isTimeOver && setActive(time.slot,index)"
                  [class.active]="activeSlot === time.slot" #{{time.slot}}>{{time.startTime}} - {{time.endTime}}</li>
                }
              </ul> -->
              <!-- <ul class="time-slot-section" [ngClass]="{'disabled-time-slots': date === undefined}">
                <li *ngFor="let time of timeSlotMapping"
                    [ngClass]="{'disabled-time-slots-li': time.isTimeOver, 'text-base font-medium cursor-pointer': !time.isTimeOver}"
                    (click)="!time.isTimeOver && setActive(time.slot)"
                    [class.active]="activeSlot === time.slot" #{{time.slot}}>
                  {{time.startTime}} - {{time.endTime}}
                </li>
              </ul> -->
              <div class="w-full mt-2">
                <p-dropdown class="timezone" (onChange)="onSlotChange($event)" placeholder="Start your time" [options]="timeSlotMapping" [(ngModel)]="activeSlot" optionLabel="startTime"></p-dropdown>
              </div>
          </div>
         
      </div>
  </div>
</section>
