import { PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { JWTService } from './jwt.service';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilityService } from '../../services/utility.service';
import { RequestMapperService } from '../../services/request-mapper.service';
import { MessagesService } from '../../services/message.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment.local';
export const qaGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const _router = inject(Router);
  const _jwt = inject(JWTService);
  const _localStorage = inject(LocalStorageService);
  const _util = inject(UtilityService);
  const platformId = inject(PLATFORM_ID);
  const isBrowser = isPlatformBrowser(platformId);
  const helper = new JwtHelperService();

  // _localStorage.storeOnAuthSuccessRoute(state.url);
  let token = _jwt.retrieveJWT();
  const decodedToken = helper.decodeToken(token);
  const isLoggedIn = Boolean(_localStorage.retrieve('qa_login'));
  // && !_jwt.hasValidJWT()

  if (isBrowser && environment.staging && !isLoggedIn) {
    _router
      .navigateByUrl(
        RequestMapperService.getAbsoluteUrl(
          `${RequestMapperService.AUTH}/${RequestMapperService.QA_LOGIN}`
        )
      )
      .then((success) =>
        success
          ? _util.showMessage(
              RequestMapperService.WARN,
              MessagesService.MESSAGE_WARN,
              MessagesService.LOGIN_REQUEST
            )
          : _util.showMessage(
              RequestMapperService.ERROR,
              MessagesService.MESSAGE_WARN,
              MessagesService.LOGIN_REQUEST
            )
      )
      .catch((err: any) => {
        console.log(err.toString());
      });
    return false;
  } else {
    return true;
  }
};
