import { Routes } from '@angular/router';

import { ServicesComponent } from './components/dashboard/services/services.component';

import { RequestMapperService } from './services/request-mapper.service';
import { authGuard } from './components/auth/auth.guard';
import { BookingComponent } from './components/services/booking/booking.component';
import { qaGuard } from './components/auth/qa.guard';

export const routes: Routes = [
  {
    path: RequestMapperService.BASE_RELATIVE_URL,
    loadComponent: () =>
      import('./components/dashboard/home/home.component').then(
        (com) => com.HomeComponent
      ),
    canActivate: [qaGuard],
  },
  {
    path: RequestMapperService.AUTH,
    children: [
      {
        path: RequestMapperService.SIGNIN_URL,
        loadComponent: () =>
          import('./components/auth/login/login.component').then(
            (com) => com.LoginComponent
          ),
        canActivate: [qaGuard],
      },
      {
        path: RequestMapperService.SIGNUP_URL,
        loadComponent: () =>
          import('./components/auth/register/register.component').then(
            (com) => com.RegisterComponent
          ),
        canActivate: [qaGuard],
      },
      {
        path: RequestMapperService.OTP_ROUTE,
        loadComponent: () =>
          import('./components/auth/otp/otp.component').then(
            (com) => com.OtpComponent
          ),
        canActivate: [qaGuard],
      },
      {
        path: RequestMapperService.FORGOT_ROUTE,
        loadComponent: () =>
          import('./components/auth/forgot/forgot.component').then(
            (com) => com.ForgotComponent
          ),
        canActivate: [qaGuard],
      },
      {
        path: RequestMapperService.CONFIRMED_PASSWORD_ROUTE,
        loadComponent: () =>
          import(
            './components/auth/confirmed-password/confirmed-password.component'
          ).then((com) => com.ConfirmedPasswordComponent),
        canActivate: [qaGuard],
      },
      {
        path: RequestMapperService.QA_LOGIN,
        loadComponent: () =>
          import('./components/auth/qa-login/qa-login.component').then(
            (com) => com.QaLoginComponent
          ),
      },
    ],
  },
  {
    canActivate: [qaGuard],

    path: RequestMapperService.CUSTOMER,
    children: [
      {
        path: RequestMapperService.ABOUT_ROUTE,
        loadComponent: () =>
          import('./components/dashboard/about-us/about-us.component').then(
            (com) => com.AboutUsComponent
          ),
      },
      {
        path: RequestMapperService.TERMS_ROUTE,
        loadComponent: () =>
          import('./components/dashboard/terms/terms.component').then(
            (com) => com.TermsComponent
          ),
      },
      {
        path: RequestMapperService.CONTACT_ROUTE,
        loadComponent: () =>
          import('./components/dashboard/contact-us/contact-us.component').then(
            (com) => com.ContactUsComponent
          ),
      },
      {
        path: RequestMapperService.SERVICE_ROUTE,
        children: [
          {
            path: RequestMapperService.BASE_RELATIVE_URL,
            component: ServicesComponent,
          },
          {
            path: RequestMapperService.SERVICE_BOOK,
            component: BookingComponent,
            canActivate: [authGuard],
          },
        ],
      },
      {
        path: RequestMapperService.MY_SERVICES,
        loadComponent: () =>
          import(
            './components/services/services-list/services-list.component'
          ).then((com) => com.ServicesListComponent),
        canActivate: [authGuard],
      },
      {
        path: RequestMapperService.PROFILE_ROUTE,
        loadComponent: () =>
          import('./components/auth/profile/profile.component').then(
            (com) => com.ProfileComponent
          ),
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: RequestMapperService.PROVIDER,
    canActivate: [qaGuard, authGuard],
    children: [
      {
        path: RequestMapperService.PROVIDER_ORDER,
        loadComponent: () =>
          import(
            './components/providers/provider-order-history/provider-order-history.component'
          ).then((com) => com.ProviderOrderHistoryComponent),
      },
      {
        path: RequestMapperService.PROVIDER_ROUTE,
        loadComponent: () =>
          import(
            './components/providers/provider-profile/provider-profile.component'
          ).then((com) => com.ProviderProfileComponent),
      },
    ],
  },
  {
    path: RequestMapperService.ADMIN_ROUTE,
    canActivate: [qaGuard, authGuard],
    children: [
      {
        path: RequestMapperService.ADMIN_SERVICES_ROUTE,
        loadComponent: () =>
          import('./components/admin/admin-parent/admin-parent.component').then(
            (com) => com.AdminParentComponent
          ),
      },
      {
        path: RequestMapperService.ADMIN_PROVIDERS_ROUTE,
        loadComponent: () =>
          import('./components/admin/admin-parent/admin-parent.component').then(
            (com) => com.AdminParentComponent
          ),
      },
      {
        path: RequestMapperService.ADMIN_CUSTOMERS_ROUTE,
        loadComponent: () =>
          import('./components/admin/admin-parent/admin-parent.component').then(
            (com) => com.AdminParentComponent
          ),
      },
    ],
  },
];
