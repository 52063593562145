<section class="stepper-section">
    <div class="container lg:my-5">
    <p-stepper>
        <p-stepperPanel header="Header I">
            <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                <!-- <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content I</div>
                </div> -->
                @if (routeToGuided) {
                  <app-guided-booking></app-guided-booking>
                } @else{
                  <app-non-guided-booking></app-non-guided-booking>
                }

                <div class="flex pt-4 pb-4 lg:pb-0 md:pb-4 justify-content-center">
                    <p-button label="Next" class="login-btn" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit(); onNext(routeToGuided ? 'guided': 'unguided')" [disabled]="checkDisable(routeToGuided ? 'guided': 'unguided')"/></div
            ></ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Header II">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
                <!-- <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content II</div>
                </div> -->
                <app-summary [prevCallback]="prevCallback" [routeToGuided]="routeToGuided"></app-summary>
                <div class="flex pt-4  pb-4 lg:pb-0 md:pb-4 justify-content-center gap-4">
                    <p-button label="Previous" class="login-btn" icon="pi pi-arrow-left" (onClick)="prevCallback.emit();" />
                    <p-button label="Next" class="login-btn" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit(); onNext('summary')" [disabled]="checkDisable('summary')"/></div
            ></ng-template>
        </p-stepperPanel>
        <p-stepperPanel header="Header III">
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback" let-index="index">
                <!-- <div class="flex flex-column h-12rem">
                    <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">Content III</div>
                </div> -->
                <app-provider-list></app-provider-list>
                <div class="flex pt-4  pb-4 lg:pb-0 md:pb-4 justify-content-center gap-4">
                    <p-button label="Previous" class="login-btn" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                    <p-button label="Submit" class="login-btn" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit(); onNext('')" [disabled]="checkDisable('')"/>
                </div>
            </ng-template>
        </p-stepperPanel>
    </p-stepper>
</div>
</section>
