import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { CommonService } from '../../../services/common.service';
import { CommonModule, DatePipe } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [
    DividerModule,
    CalendarModule,
    FormsModule,
    CommonModule,
    DropdownModule,
  ],
  providers: [DatePipe],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.css',
})
export class SummaryComponent implements OnInit, AfterViewInit {
  minDate!: Date;

  maxDate!: Date;
  date!: Date;
  public serviceDetails: any[] = [];
  @Input() routeToGuided: boolean | undefined;
  @Input() prevCallback!: EventEmitter<any>;
  @ViewChild('slot1') slot1!: ElementRef;
  @ViewChild('slot2') slot2!: ElementRef;
  @ViewChild('slot3') slot3!: ElementRef;
  @ViewChild('slot4') slot4!: ElementRef;
  @ViewChild('slot5') slot5!: ElementRef;
  @ViewChild('slot6') slot6!: ElementRef;

  activeSlot!: HTMLElement | null;
  // timeSlot: { startTime: number; endTime: number } | null = null;
  timeSlot: { slot: string; startTime: number; endTime: number } | null = null;

  public isTimeOver?: boolean;
  // timeSlotMapping: { [key: string]: { startTime: number; endTime: number } } = {
  //   '8:30AM - 11:30AM': { startTime: 1622033400000, endTime: 1622044200000 },
  //   '9:00AM - 1:15PM': { startTime: 1622035200000, endTime: 1622046900000 },
  //   '9:30AM - 12:30PM': { startTime: 1622037000000, endTime: 1622043000000 },
  //   '1:00PM - 3:10PM': { startTime: 1622079000000, endTime: 1622086200000 },
  //   '4:00PM - 5:00PM' : { startTime: 1656634800000, endTime: 1656639600000 },
  //   '6:00PM - 8:00PM': { startTime: 1656643200000, endTime: 1656662400000 }
  // };
  timeSlotMapping: any = [
    {
      index: 0,
      slot: 'slot1',
      startTime: '07:00 AM',
      endTime: '07:30 AM',
      isTimeOver: false,
    },
    {
      index: 1,
      slot: 'slot2',
      startTime: '07:30 AM',
      endTime: '08:00 AM',
      isTimeOver: false,
    },
    {
      index: 2,
      slot: 'slot3',
      startTime: '08:00 AM',
      endTime: '08:30 AM',
      isTimeOver: false,
    },
    {
      index: 3,
      slot: 'slot4',
      startTime: '08:30 AM',
      endTime: '09:00 AM',
      isTimeOver: false,
    },
    {
      index: 4,
      slot: 'slot5',
      startTime: '09:00 AM',
      endTime: '09:30 AM',
      isTimeOver: false,
    },
    {
      index: 5,
      slot: 'slot6',
      startTime: '09:30 AM',
      endTime: '10:00 AM',
      isTimeOver: false,
    },
    {
      index: 6,
      slot: 'slot7',
      startTime: '10:00 AM',
      endTime: '10:30 AM',
      isTimeOver: false,
    },
    {
      index: 7,
      slot: 'slot8',
      startTime: '10:30 AM',
      endTime: '11:00 AM',
      isTimeOver: false,
    },
    {
      index: 8,
      slot: 'slot9',
      startTime: '11:00 AM',
      endTime: '11:30 AM',
      isTimeOver: false,
    },
    {
      index: 9,
      slot: 'slot10',
      startTime: '11:30 AM',
      endTime: '12:00 PM',
      isTimeOver: false,
    },
    {
      index: 10,
      slot: 'slot11',
      startTime: '12:00 PM',
      endTime: '12:30 PM',
      isTimeOver: false,
    },
    {
      index: 11,
      slot: 'slot12',
      startTime: '12:30 PM',
      endTime: '01:00 PM',
      isTimeOver: false,
    },
    {
      index: 12,
      slot: 'slot13',
      startTime: '01:00 PM',
      endTime: '01:30 PM',
      isTimeOver: false,
    },
    {
      index: 13,
      slot: 'slot14',
      startTime: '01:30 PM',
      endTime: '02:00 PM',
      isTimeOver: false,
    },
    {
      index: 14,
      slot: 'slot15',
      startTime: '02:00 PM',
      endTime: '02:30 PM',
      isTimeOver: false,
    },
    {
      index: 15,
      slot: 'slot16',
      startTime: '02:30 PM',
      endTime: '03:00 PM',
      isTimeOver: false,
    },
    {
      index: 16,
      slot: 'slot17',
      startTime: '03:00 PM',
      endTime: '03:30 PM',
      isTimeOver: false,
    },
    {
      index: 17,
      slot: 'slot18',
      startTime: '03:30 PM',
      endTime: '04:00 PM',
      isTimeOver: false,
    },
    {
      index: 18,
      slot: 'slot19',
      startTime: '04:00 PM',
      endTime: '04:30 PM',
      isTimeOver: false,
    },
    {
      index: 19,
      slot: 'slot20',
      startTime: '04:30 PM',
      endTime: '05:00 PM',
      isTimeOver: false,
    },{
      index: 20,
      slot: 'slot21',
      startTime: '05:00 PM',
      endTime: '05:30 PM',
      isTimeOver: false,
    }
  ];

  constructor(
    private _common: CommonService,
    private _cdk: ChangeDetectorRef,
    private _datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.prapareCalender();
    if (this._common.serviceRequest.serviceDate) {
      this.date = new Date(this._common.serviceRequest.serviceDate * 1000);
    } else {
      // this.date = new Date();
      // this.date.setDate(this.date.getDate() + 1);
    }
    this.timeSlot = this._common.serviceRequest.timeSlot;
    this.serviceDetails = this._common.serviceRequest.serviceDetails;
    this.addHoursToEndTime(this._common.serviceTimeInHours);
  }

  ngAfterViewInit(): void {
    this.onDateSelect(this.date);
    if (this.timeSlot) {
      this.setActiveSlotByTimeSlot();
    }
    this._cdk.detectChanges();
  }

  formatDate(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  onSlotChange(event: any): void {
    let slot = event.value;
    this.setActive(slot, slot.index);
  }

  setActive(slot: any, index: number): void {
    if (this.activeSlot) {
      this.activeSlot?.classList?.remove('active');
    }
    slot?.classList?.add('active');
    this.activeSlot = slot;
    this.timeSlot = this.timeSlotMapping[index];
  }

  addHoursToEndTime(timeDuration: number) {
    this.timeSlotMapping.forEach(
      (slot: { startTime: string; endTime: string }) => {
        let [time, meridian] = slot.startTime.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (meridian === 'PM' && hours !== 12) hours += 12;
        if (meridian === 'AM' && hours === 12) hours = 0;

        hours += timeDuration;

        const newDate = new Date();
        newDate.setHours(hours);
        newDate.setMinutes(minutes);

        const newHours = newDate.getHours() % 12 || 12;
        const newMinutes = newDate.getMinutes().toString().padStart(2, '0');
        const newMeridian = newDate.getHours() >= 12 ? 'PM' : 'AM';

        slot.endTime = `${newHours}:${newMinutes} ${newMeridian}`;
      }
    );
  }

  onSaveData() {
    this._common.serviceRequest.serviceDate = this.date.getTime() / 1000;
    this._common.serviceRequest.timeSlot = this.timeSlot;
  }

  private setActiveSlotByTimeSlot(): void {
    const matchingSlot = Object.keys(this.timeSlotMapping).find((key) => {
      const slotValue = this.timeSlotMapping[key];
      return (
        slotValue.startTime === this.timeSlot!.startTime &&
        slotValue.endTime === this.timeSlot!.endTime
      );
    });
    if (matchingSlot) {
      switch (matchingSlot) {
        case this.slot1.nativeElement.innerHTML:
          this.activeSlot = this.slot1.nativeElement;
          break;
        case this.slot2.nativeElement.innerHTML:
          this.activeSlot = this.slot2.nativeElement;
          break;
        case this.slot3.nativeElement.innerHTML:
          this.activeSlot = this.slot3.nativeElement;
          break;
        case this.slot4.nativeElement.innerHTML:
          this.activeSlot = this.slot4.nativeElement;
          break;
        case this.slot5.nativeElement.innerHTML:
          this.activeSlot = this.slot5.nativeElement;
          break;
        case this.slot6.nativeElement.innerHTML:
          this.activeSlot = this.slot6.nativeElement;
          break;
        default:
          this.activeSlot = null;
      }
      if (this.activeSlot) {
        this.activeSlot.classList.add('active');
      }
    }
    let slot: any = this.timeSlot?.slot;
    this.activeSlot = slot;
  }

  prapareCalender() {
    // let today = new Date();
    // today.setDate(today.getDate() + 1);
    // const month = today.getMonth();
    // const year = today.getFullYear();
    // const prevMonth = month === 0 ? 11 : month - 1;
    // const prevYear = prevMonth === 11 ? year - 1 : year;
    // const nextMonth = month === 11 ? 0 : month + 1;
    // const nextYear = nextMonth === 0 ? year + 1 : year;
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());
    // this.minDate.setMonth(month);
    // this.minDate.setFullYear(prevYear);
    // this.maxDate = new Date();
    // this.maxDate.setMonth(nextMonth);
    // this.maxDate.setFullYear(nextYear);
    let today = new Date();
    today.setDate(today.getDate());

    // Set minDate to today's date
    this.minDate = new Date(today);

    // Set maxDate to one month from today
    let maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth() + 1);

    // If month overflows, handle year increment
    if (maxDate.getDate() < today.getDate()) {
      maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 0);
    }

    // Set the hours to 23:59:59 for maxDate to include the entire day
    maxDate.setHours(23, 59, 59, 999);

    // Set the instance variable
    this.maxDate = maxDate;
  }

  private convertTimeToDate(time: string): Date {
    const [timeString, modifier] = time.split(' ');
    const [hours, minutes] = timeString.split(':');
    let hoursNumber = parseInt(hours, 10);

    if (modifier === 'PM' && hoursNumber !== 12) {
      hoursNumber += 12;
    }
    if (modifier === 'AM' && hoursNumber === 12) {
      hoursNumber = 0;
    }

    const date = new Date();
    date.setHours(hoursNumber);
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  onDateSelect(selectedDateTime: any): void {
    if (!selectedDateTime || isNaN(new Date(selectedDateTime).getTime())) {
      return;
    }

    const currentDate = new Date();
    const selectedDate = new Date(selectedDateTime);

    const currentDateString = this._datePipe.transform(
      currentDate,
      'dd/MM/yyyy'
    );
    const selectedDateString = this._datePipe.transform(
      selectedDate,
      'dd/MM/yyyy'
    );

    if (currentDateString === selectedDateString) {
      for (const element of this.timeSlotMapping) {
        const slotStartTime = this.convertTimeToDate(element.endTime);
        if (slotStartTime <= currentDate) {
          element.isTimeOver = true;
        } else {
          element.isTimeOver = false;
        }
      }
    } else {
      for (const element of this.timeSlotMapping) {
        element.isTimeOver = false;
      }
    }
    this._cdk.detectChanges();
  }
}
