import { AfterContentChecked, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StepperModule } from 'primeng/stepper';
import { ButtonModule } from 'primeng/button';
import { GuidedBookingComponent } from '../guided-booking/guided-booking.component';
import { TransmissionService } from '../../../transmission/transmission.service';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { DOMAIN } from '../../../transmission/domainTypes';
import { UtilityService } from '../../../services/utility.service';
import { MessagesService } from '../../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NonGuidedBookingComponent } from '../non-guided-booking/non-guided-booking.component';
import { SummaryComponent } from '../summary/summary.component';
import { ProviderListComponent } from '../provider-list/provider-list.component';
import { CommonService } from '../../../services/common.service';
import { BOOKING } from '../enum';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-booking',
  standalone: true,
  imports: [
    StepperModule,
    ButtonModule,
    GuidedBookingComponent,
    NonGuidedBookingComponent,
    SummaryComponent,
    ProviderListComponent,
  ],
  templateUrl: './booking.component.html',
  styleUrl: './booking.component.css',
})
export class BookingComponent implements OnInit,AfterContentChecked, OnDestroy {
  public routeToGuided: boolean | undefined;
  @ViewChild(GuidedBookingComponent) guidedBooking!: GuidedBookingComponent;
  @ViewChild(NonGuidedBookingComponent)
  nonGuidedBooking!: NonGuidedBookingComponent;
  @ViewChild(SummaryComponent) summary!: SummaryComponent;
  @ViewChild(ProviderListComponent) providers!: ProviderListComponent;
  public page: number = 1;
  public rows: number = 10;
  @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
      $event.returnValue =true;
    }

  constructor(
    private _activeRoute: ActivatedRoute,
    private _common: CommonService,
    private _route: Router,
    private _transmit: TransmissionService,
    private _util: UtilityService,
    private _cdk: ChangeDetectorRef,
    private _loaderService: NgxUiLoaderService
  ) {}

  async ngOnInit(): Promise<void> {
    this._activeRoute.queryParams.subscribe((value) => {
      if (!value[BOOKING.GUIDLINE]) {
        this._route.navigate([`${RequestMapperService.ROOT_URL}`]);
        return;
      } else {
        this.routeToGuided = JSON.parse(value[BOOKING.GUIDLINE]);
      }
    });
    this._common.serviceRequest.serviceGuidelineType = this.routeToGuided
      ? BOOKING.GUIDED
      : BOOKING.UNGUIDED;

    await this.getAllServices();
  }

  ngAfterContentChecked(): void {
    this._cdk.detectChanges();
  }

  public onNext(type: string) {
    switch (type) {
      case BOOKING.GUIDED:
        this.guidedBooking.onSaveData();
        break;
      case BOOKING.UNGUIDED:
        this.nonGuidedBooking.onSaveData();
        break;
      case BOOKING.SUMMARY:
        this.summary.onSaveData();
        break;
      default:
        this.providers.submitSelectedProviders();
        break;
    }
  }

  public async getAllServices(): Promise<void> {
    await this._transmit
      .executeGetRequestPromise(
        `${RequestMapperService.GET_SERVICES_DETAILS}`,
        DOMAIN.SERVER
      )
      .then((res: any) => {
        if (res) {
          let serviceData = res.data;
          serviceData.forEach((ele: any) => {
            ele.count = 0;
          });
          this._common.serviceRequest.serviceDetails = serviceData;
          this._common.setData(serviceData);
        }
      })
      .catch((err: string) => {
        this._util.showMessage(
          RequestMapperService.ERROR,
          MessagesService.MESSAGE_ERROR,
          err
        );
      });

  }


  public checkDisable(type:any): boolean{
    let disable = false;
    switch (type) {
      case BOOKING.GUIDED:
        return this.guidedBooking?.serviceData?.every((ele: any) => ele.count === 0) || this.guidedBooking?.serviceTimeInHours > 9  ;
      case BOOKING.UNGUIDED:
        // disable logic for non guided page
        if (this.nonGuidedBooking?.serviceTimeHrs && this.nonGuidedBooking?.serviceTimeHrs>9) {
          return true
        }else{
          return !(this.nonGuidedBooking?.serviceTimeHrs)
        }
        break;
      case BOOKING.SUMMARY:
        disable =true;
        if (this.summary?.timeSlot !== undefined) {
          disable =false;
        }
        break;
      default:
        disable = true;
        if(this.providers?.selectedProviders.length>0){
          disable = false;
        }
        break;
    }
    return disable;
  }

  ngOnDestroy(): void {
      this._common.serviceRequest = {};
      this._common.providerList = [];
  }
}
