import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment.local';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    MessageService,
    provideAnimations(),
    provideHttpClient(withFetch()),
    DynamicDialogRef,
    DialogService,
    NgxUiLoaderService,
    provideNgxStripe(environment.STRIPE_PK_KEY)
  ],
};
