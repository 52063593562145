import { PLATFORM_ID, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { JWTService } from './jwt.service';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../../services/local-storage.service';
import { UtilityService } from '../../services/utility.service';
import { RequestMapperService } from '../../services/request-mapper.service';
import { MessagesService } from '../../services/message.service';
import { JwtHelperService } from '@auth0/angular-jwt';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const _router = inject(Router);
  const _jwt = inject(JWTService);
  const _localStorage = inject(LocalStorageService);
  const _util = inject(UtilityService);
  const platformId = inject(PLATFORM_ID);
  const isBrowser = isPlatformBrowser(platformId);
  const helper = new JwtHelperService();

  _localStorage.storeOnAuthSuccessRoute(state.url);
  let token = _jwt.retrieveJWT();
  const decodedToken = helper.decodeToken(token);

  if (isBrowser && !_jwt.hasValidJWT()) {
    _router
      .navigateByUrl(
        RequestMapperService.getAbsoluteUrl(
          `${RequestMapperService.AUTH}/${RequestMapperService.SIGNIN_URL}`
        )
      )
      .then((success) =>
        success
          ? _util.showMessage(
              RequestMapperService.WARN,
              MessagesService.MESSAGE_WARN,
              MessagesService.LOGIN_REQUEST
            )
          : _util.showMessage(
              RequestMapperService.ERROR,
              MessagesService.MESSAGE_ERROR,
              MessagesService.GENERIC_FAILURE
            )
      )
      .catch((err: any) => {
        console.log(err.toString());
      });
    return false;
  } else if (
    decodedToken &&
    decodedToken.roleId === 2 &&
    (isCustomerRoutes(state) || isAdminRoutes(state))
  ) {
    _router
      .navigateByUrl(
        RequestMapperService.getAbsoluteUrl(
          `${RequestMapperService.BASE_RELATIVE_URL}`
        )
      )
      .then((success) =>
        success
          ? _util.showMessage(
              RequestMapperService.WARN,
              MessagesService.MESSAGE_WARN,
              MessagesService.RESTRICTED
            )
          : _util.showMessage(
              RequestMapperService.ERROR,
              MessagesService.MESSAGE_ERROR,
              MessagesService.GENERIC_FAILURE
            )
      )
      .catch((err: any) => {
        console.log(err.toString());
      });
    return false;
  } else if (
    decodedToken &&
    decodedToken.roleId === 3 &&
    (isProviderRoutes(state) || isAdminRoutes(state))
  ) {
    _router
      .navigateByUrl(
        RequestMapperService.getAbsoluteUrl(
          `${RequestMapperService.BASE_RELATIVE_URL}`
        )
      )
      .then((success) =>
        success
          ? _util.showMessage(
              RequestMapperService.WARN,
              MessagesService.MESSAGE_WARN,
              MessagesService.RESTRICTED
            )
          : _util.showMessage(
              RequestMapperService.ERROR,
              MessagesService.MESSAGE_ERROR,
              MessagesService.GENERIC_FAILURE
            )
      )
      .catch((err: any) => {
        console.log(err.toString());
      });
    return false;
  } else if (
    decodedToken &&
    decodedToken.roleId === 1 &&
    (isProviderRoutes(state) || isCustomerRoutes(state))
  ) {
    _router
      .navigateByUrl(
        RequestMapperService.getAbsoluteUrl(
          `${RequestMapperService.BASE_RELATIVE_URL}`
        )
      )
      .then((success) =>
        success
          ? _util.showMessage(
              RequestMapperService.WARN,
              MessagesService.MESSAGE_WARN,
              MessagesService.RESTRICTED
            )
          : _util.showMessage(
              RequestMapperService.ERROR,
              MessagesService.MESSAGE_ERROR,
              MessagesService.GENERIC_FAILURE
            )
      )
      .catch((err: any) => {
        console.log(err.toString());
      });
    return false;
  }

  return true;
};

const isAdminRoutes = (state: any): boolean => {
  return (
    state.url.includes('/admin/services') ||
    state.url.includes('/admin/customers') ||
    state.url.includes('/admin/providers')
  );
};

const isCustomerRoutes = (state: any): boolean => {
  return (
    state.url.includes('/service/booking') ||
    state.url.includes('/my-services') ||
    state.url.includes('/customer/profile')
  );
};

const isProviderRoutes = (state: any): boolean => {
  return (
    state.url.includes('/provider-order') ||
    state.url.includes('/provider/profile')
  );
};
