<section class="inner-banner-section service-bg">
  <div class="container">
    <div class="grid">
      <div class="col-12 flex justify-content-center">
        <h2 class="font-medium m-0">Our Services</h2>
      </div>
    </div>
  </div>
</section>

<section class="content-section service-content">
  <div class="container">
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-4">
        <p-card header="Residential Cleaning">
          <ng-template pTemplate="header">
            <img src="/assets/img/house_cleaning.jpg" alt="house_cleaning" />
          </ng-template>
          <p>
            Keep your home tidy and clean with our routine cleaning services,
            tailored to your needs. For a thorough cleaning that reaches every
            nook and cranny, our deep cleaning service ensures your home is
            spotless. Whether you’re moving in or out, we provide comprehensive
            cleaning services to make the transition smooth and hassle-free.
            Simply schedule more hours than a regular cleaning and let the
            cleaner know the level of detail you require.
          </p>
          <ng-template pTemplate="footer">
            <a class="btn custom-btn mt-25" (click)="navigateToBookService()">
              Book a Service
              <i class="material-icons svg-icon transform-angle"
                >arrow_forward</i
              >
            </a>
          </ng-template>
        </p-card>
      </div>

      <!-- <div class="col-12 md:col-6 lg:col-4 ">
      <p-card header="Garage Cleaning"  >
        <ng-template pTemplate="header">
            <img  src="/assets/img/garbage_cleaning.jpg" alt="garage_cleaning" />
        </ng-template>
        <p>
          As a app web crawler expert, I help organizations adjust to the expanding significance of internet promoting.
        </p>
        <ng-template pTemplate="footer">
          <a  class="btn custom-btn mt-25" (click)="openDialog()">
            Book a Service
            <i class="material-icons svg-icon transform-angle">arrow_forward</i>
          </a>
        </ng-template>
    </p-card>
    </div> -->

      <!-- <div class="col-12 md:col-6 lg:col-4 ">
      <p-card header="Bathroom Cleaning"  >
        <ng-template pTemplate="header">
            <img  src="/assets/img/bathroom_cleaning.jpg" alt="bathroom_cleaning" />
        </ng-template>
        <p>
          As a app web crawler expert, I help organizations adjust to the expanding significance of internet promoting.
        </p>
        <ng-template pTemplate="footer">
          <a  class="btn custom-btn mt-25" (click)="openDialog()">
            Book a Service
            <i class="material-icons svg-icon transform-angle">arrow_forward</i>
          </a>
        </ng-template>
    </p-card>
    </div> -->

      <!-- <div class="col-12 md:col-6 lg:col-4 ">
      <p-card header="Chimney Cleaning"  >
        <ng-template pTemplate="header">
            <img  src="/assets/img/chimney_cleaning.jpg" alt="chimney_cleaning" />
        </ng-template>
        <p>
          As a app web crawler expert, I help organizations adjust to the expanding significance of internet promoting.
        </p>
        <ng-template pTemplate="footer">
          <a  class="btn custom-btn mt-25" (click)="openDialog()">
            Book a Service
            <i class="material-icons svg-icon transform-angle">arrow_forward</i>
          </a>
        </ng-template>
    </p-card>
    </div> -->

      <div class="col-12 md:col-6 lg:col-4">
        <p-card header="Commercial Cleaning">
          <ng-template pTemplate="header">
            <img src="/assets/img/office_cleaning.jpg" alt="office_cleaning" />
          </ng-template>
          <p>
            Ensure a clean and productive workspace with our comprehensive
            office cleaning services, tailored to your schedule and specific
            needs. Create a welcoming shopping environment with our specialized
            retail space cleaning services, designed to maintain a spotless and
            inviting store.
          </p>
          <ng-template pTemplate="footer">
            <a class="btn custom-btn mt-25" (click)="navigateToBookService()">
              Book a Service
              <i class="material-icons svg-icon transform-angle"
                >arrow_forward</i
              >
            </a>
          </ng-template>
        </p-card>
      </div>

      <div class="col-12 md:col-6 lg:col-4">
        <p-card header="Specialty Cleaning">
          <ng-template pTemplate="header">
            <img src="/assets/img/sanitizing.jpg" alt="sanitizing" />
          </ng-template>
          <p>
            Refresh your carpets with our professional carpet cleaning services,
            removing dirt, stains, and allergens to leave them looking and
            feeling brand new. Let the light in with our expert window washing
            services, ensuring your windows are crystal clear and streak-free.
            After renovation or construction, our post-construction cleaning
            services will make your space pristine and ready for use, handling
            all debris and dust removal.
          </p>
          <ng-template pTemplate="footer">
            <a class="btn custom-btn mt-25" (click)="navigateToBookService()">
              Book a Service
              <i class="material-icons svg-icon transform-angle"
                >arrow_forward</i
              >
            </a>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>
</section>
