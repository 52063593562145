import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { TableModule } from 'primeng/table';
import { CommonService } from '../../../services/common.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../services/utility.service';
import { TransmissionService } from '../../../transmission/transmission.service';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { MessagesService } from '../../../services/message.service';
import { DOMAIN } from '../../../transmission/domainTypes';
import { CommonModule } from '@angular/common';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { Router } from '@angular/router';
import { ProviderDetailsDialogComponent } from '../../shared/provider-details-dialog/provider-details-dialog.component';
import { SanitizationDirective } from '../../shared/sanitization.directive';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'app-provider-list',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    InputTextModule,
    RatingModule,
    FormsModule,
    CommonModule,
    SanitizationDirective,
    KeyFilterModule,
    PaginatorModule
  ],
  templateUrl: './provider-list.component.html',
  styleUrl: './provider-list.component.css',
})
export class ProviderListComponent implements OnInit {
  @Input() prevCallback!: EventEmitter<any>;
  public selectedProviders: any = [];
  public serviceProviders: any = [];
  public searchZip: string = '';
  public searchCity: string = '';
  public searchState: string = '';
  // public filteredProviders:any =[];
  public bookingPayload:any ={};
  public userName:string='';
  public mobileNumber:string='';
  public userAddress:any={};
  alphabetOnly = /^[a-zA-Z]*$/;
  public currentCustomerZip: string = ''
  public page: number = 1;
  public rows: number = 10;
  public first: number = 0;
  public totalProvidersCount: number = 0
  public paginateCurrentCustomerZipWise: boolean = true

  constructor(
    private _common: CommonService,
    private _transmit: TransmissionService,
    private _util: UtilityService,
    private _dynamicDialogRef: DynamicDialogRef,
    private _dialogService: DialogService,
    private _router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this._common.getUserData().subscribe({
      next: (data) => {
        if (data) {
          this.userName = data.name;
          this.mobileNumber = data.mobileNumber;
          this.userAddress = data.addressDetails;
          this.currentCustomerZip = data?.addressDetails?.zip
          this.paginateCurrentCustomerZipWise = true
          this.getAllProviders(1, 10, this.currentCustomerZip);
        }
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
      complete: () => {
        // Handle completion
      },
    });
    if(this._common.serviceRequest){
      this.bookingPayload = JSON.parse(JSON.stringify(this._common.serviceRequest));
    }
  }


  async submitSelectedProviders(): Promise<void> {
    this.bookingPayload.providerList = this.selectedProviders;
    this.bookingPayload.estTime = this._common.serviceTimeInHours;
    this.bookingPayload.customerName = this.userName;
    this.bookingPayload.customerAddress = this.userAddress;
    this.bookingPayload.mobileNumber = this.mobileNumber;
    this.bookingPayload.serviceDetails = this.bookingPayload.serviceDetails.filter((ele:any)=> ele.count >0);
    if (this.selectedProviders.length > 0) {
      await this._transmit.executePostRequestPromise(`${RequestMapperService.SERVICE_REQUEST_RAISE}`, DOMAIN.SERVER, this.bookingPayload)
        .then((res: any) => {
          if(res.success){
            this.openDialog();
          }
        })
        .catch((err: string) => {
          this._util.showMessage(
            RequestMapperService.ERROR,
            MessagesService.MESSAGE_ERROR,
            err
          );
        });
    } else {
      this._util.showMessage(
        RequestMapperService.WARN,
        MessagesService.MESSAGE_WARNING,
        MessagesService.NO_PROVIDERS_SELECTED
      );
    }
  }

  getStarArray(rating: number): any[] {
    return Array(Math.floor(rating)).fill(0);
  }

  onPageChange(event: any) {
    this.page = event.page + 1;
    this.rows = event.rows;
    if (this.paginateCurrentCustomerZipWise) {
      this.getAllProviders(this.page, this.rows, this.currentCustomerZip);
    }else{
      this.getAllProviders(this.page, this.rows, this.searchZip, this.searchCity, this.searchState);
    }
  }
  public async getAllProviders(page: number, limit: number, zip?: any, city?: any, state?: any): Promise<void> {
    let apiUrl = `${RequestMapperService.GET_ALL_PROVIDERS}?page=${page}&limit=${limit}`;

    if (city) {
      apiUrl += `&city=${city}`;
    }
    if (state) {
      apiUrl += `&state=${state}`;
    }
    if (zip) {
      apiUrl += `&zip=${zip}`;
    }
    await this._transmit
     .executeGetRequestPromise(apiUrl, DOMAIN.SERVER)
     .then((res) => {
      if (res && res.data) {
          this.serviceProviders = res.data.providers;
          this.totalProvidersCount = res.data.totalProvidersCount;
      }
      })
     .catch((err) => {
        console.log(err.toString());
      });
  }
searchProviders(): void {
  if (!this.searchZip.trim() && !this.searchCity.trim() && !this.searchState.trim()) {
    return;
  }
  this.page = 1;
  this.rows = 10;
  this.paginateCurrentCustomerZipWise = false;
  this.getAllProviders(this.page, this.rows, this.searchZip, this.searchCity, this.searchState)
}

async refreshProviders(): Promise<void> {
  this.searchZip = '';
  this.searchCity = '';
  this.searchState = '';
  this.page = 1;
  this.rows = 10;
  this.paginateCurrentCustomerZipWise = true;
  await this.getAllProviders(this.page, this.rows, this.currentCustomerZip);
}

  public openDialog(): void{
    let dialogWidth = "30rem"
    if(window.innerWidth <= 768){
        dialogWidth = '90vw'
    }else if(window.innerWidth <= 480){
        dialogWidth = '95vw'
    }

    this._dynamicDialogRef = this._dialogService.open(CommonDialogComponent,{
      data:{
        type: 'submit',
        title:'Request Submitted Successfully!',
        message: 'Service Provider will response you shortly',
        component: 'provider-list'
      },
      modal: true,
      resizable: true,
      width: dialogWidth,
      height: 'auto',
      closable: true,
      dismissableMask: true,
      contentStyle: {overflow: 'auto'}
    });

    this._dynamicDialogRef.onClose.subscribe((result) => {
      if(result && result?.done){
        this._router.navigate(
          [`${RequestMapperService.CUSTOMER}/${RequestMapperService.MY_SERVICES}`]
        )
      }else{
        this._router.navigate(
          [`${RequestMapperService.CUSTOMER}/${RequestMapperService.MY_SERVICES}`]
        )
      }
      this._dynamicDialogRef.destroy();
    })
  }

  public getFirstNameWithInitial(fullName:string) {
    const nameParts = fullName.split(" ");
    if (nameParts.length < 2) return fullName; // Return the original name if there's no last name
  
    const firstName = nameParts[0];
    const lastInitial = nameParts[1][0]; // Get the first character of the last name
    return `${firstName} ${lastInitial}`;
  }

  public openProviderDetails(data:any): void{
    this._dynamicDialogRef = this._dialogService.open(ProviderDetailsDialogComponent,{
      data,
      modal: true,
      resizable: true,
      width: '30rem',
      height: 'auto',
      closable: true,
      dismissableMask: true,
      contentStyle: {overflow: 'auto'}
    })
  }
}
