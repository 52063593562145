<section class="service-booking-section">
    <div class="container">
        <div class="grid">
            <div class="col-12 lg:col-9 md:col-12">
                <div class="book-service ">
                    <h6 class="m-0 text-xl font-semibold">Service Details</h6>
                    <ul>
                        @for( item of serviceData; let i = $index; track item.icon){
                            @if (item.serviceType === 'general' ) {
                                <li class="flex justify-content-between">
                                   <span class="flex align-items-center gap-3 text-lg font-medium">
                                    @if (item.serviceName === 'Bedrooms') {
                                        <i class="material-icons" style="color: var(--bcd-btn);">bed</i>
                                    }
                                    @if (item.serviceName === 'Bathrooms') {
                                        <i class="material-icons" style="color: var(--bcd-btn);">bathtub</i>
                                    }
                                    @if (item.serviceName === 'kitchen') {
                                        <i class="material-icons" style="color: var(--bcd-btn);">restaurant</i>
                                    }
                                    @if (item.serviceName === 'Living Room') {
                                        <i class="material-icons" style="color: var(--bcd-btn);">meeting_room</i>
                                    }
                                     {{item.serviceName | titlecase}}
                                   </span>
                                   <span class="flex align-items-center gap-3 font-xl">
                                     <button class="bg-transparent border-none text-3xl cursor-pointer" (click)="bookedCount(i, 'dec')">-</button>
                                     <small class="text-xl " style="color: var(--bcd-btn);">{{item.count}}</small>
                                     <button class="bg-transparent border-none text-3xl cursor-pointer" (click)="bookedCount(i, 'inc')">+</button>
                                   </span>
                                </li>
                            }
                        }@empty{
                        <li>No items found.</li>
                        }
                       </ul>
                </div>
                <p-divider class="flex w-full"></p-divider>
                <div class="extra-service py-2">
                    <h6 class="m-0 text-xl font-semibold">Additonal Options</h6>
                    <ul>
                        @for (item of serviceData; track item; let index = $index) {
                            @if (item.serviceType === 'extra' ) {
                                <li (click)="getExtraItems(item, index)" class="cursor-pointer" [ngClass]="{'active': item.count === 1}">{{ item.serviceName | titlecase }}</li>
                            }
                        }
                      </ul>
                </div>
            </div>
            <div class="col-12 lg:col-3 md:col-12">
                <h6  class="m-0 text-xl font-semibold text-center">Estimated Time</h6>
                <div class="time-section flex flex-column">
                    <span class="font-semibold text-lg py-3 flex justify-content-center border-bottom-1" style="border-color: var(--bcd-border);">Total Hour</span>
                    <!-- <p-divider class="w-full"></p-divider> -->
                    <span class="flex flex-column align-items-center py-4 gap-4 border-bottom-1" style="border-color: var(--bcd-border);">
                        <img src="/assets/img/time-clock.svg" alt="time-clock" srcset="">
                        <b class="text-7xl font-semibold flex align-items-center" style="color: var(--bcd-btn);">{{ serviceTimeInHours }}
                        <small class="px-2 text-3xl" style="color: var(--bcd-textBlack);">hr.</small>
                        </b>
                        @if (serviceTimeInHours>9) {
                            <small style="color: #e24c4c; margin-bottom: -1rem;" class="text-center">Service time cannot exceed 9 hours.</small>
                          }
                    </span>
                    <!-- <p-divider class="w-full"></p-divider> -->
                    <span class="p-3 lg:p-2 h-full font-medium flex align-items-center text-center line-height-3 md:justify-content-center" style="font-size: 11px;">This is an estimated time. The exact duration of the service will be determined after the service is completed.</span>
                </div>
            </div>
        </div>
    </div>
</section>
