<section class="overflow-hidden">
  <div class="container">
    <div class="grid">
      <div class="col-12 px-0">
        <div class="flex justify-content-between lg:align-items-center search-responsive">
          <h6 class="text-xl lg:text-2xl font-semibold m-0  lg:white-space-nowrap">Need a Service Provider for a Different Location?</h6>
            <div class="flex flex-column md:flex-row lg:flex-row lg:align-items-center gap-3 lg:justify-content-end">
                <input type="text" pInputText class="w-full lg:w-3" [(ngModel)]="searchZip" [pKeyFilter]="'int'" placeholder="Zip" (keydown.enter)="searchProviders()" inputSanitization supportedCharaterRange="'0-9-'"/>
                <input type="text" pInputText class="w-full lg:w-3" [(ngModel)]="searchCity" [pKeyFilter]="alphabetOnly" placeholder="City" (keydown.enter)="searchProviders()"/>
                <input type="text" pInputText class="w-full lg:w-3" [(ngModel)]="searchState" [pKeyFilter]="alphabetOnly" placeholder="State" (keydown.enter)="searchProviders()"/>
                <div class="flex ">
                <p-button icon="pi pi-search" class="searchBtn" (click)="searchProviders()"></p-button>
                <p-button icon="pi pi-refresh" class="refreshBtn" [rounded]="true" [text]="true" (click)="refreshProviders()"></p-button>
              </div>
              </div>
        </div>
      </div>
      <div class="col-12 pb-0 px-0 pt-5">
        <h6 class="text-xl lg:text-2xl font-semibold m-0  lg:white-space-nowrap">Service Providers Available In Your Area!</h6>
      </div>
      <div class="col-12 px-0">
        <div class="px-2">
          @if (totalProvidersCount>0) {
            <p-table class="serviceProvider-table"
              [value]="serviceProviders"
              [(selection)]="selectedProviders"
              dataKey="email"

            >
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    Select
                  </th>
                  <th>Name</th>
                  <!-- <th>Zip</th>
                  <th>City</th>
                  <th>State</th> -->
                  <th>Rate/hour ($)</th>
                  <th>Rating</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-provider>
                <tr>
                  <td>
                    <p-tableCheckbox class="table-checkbox" [value]="provider"></p-tableCheckbox>
                  </td>
                  <td style="color: var(--bcd-btn);cursor: pointer;" (click)="openProviderDetails(provider)">{{ getFirstNameWithInitial(provider.name) | titlecase }}</td>
                  <!-- <td>{{ provider.addressDetails.zip }}</td>
                  <td>{{ provider.addressDetails.city }}</td>
                  <td>{{ provider.addressDetails.state }}</td> -->
                  <td>{{ provider.ratePerHour }}</td>
                  <!-- <td>{{provider.rating}}</td> -->
                  <td >
                      <p-rating [(ngModel)]="provider.avgRating"  [readonly]="true" [cancel]="false"></p-rating>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          }@else {
            <p-table class="serviceProvider-table"
              [value]="serviceProviders"
              dataKey="email"

            >
              <ng-template pTemplate="header">
                <tr>
                  <th>
                    Select
                  </th>
                  <th>Name</th>
                  <th>Rate/hour ($)</th>
                  <th>Rating</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr style="background: transparent;">
                  <td colspan="4" class="no-data-img">
                    <figure>
                      <img src="/assets/img/nodata_found.svg" alt="nodata _found" srcset="" class="w-full h-full">
                    </figure>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          }
          @if(totalProvidersCount > 10){
            <p-paginator  [rows]="rows" [totalRecords]="totalProvidersCount" [first]="first" [rowsPerPageOptions]="[10, 15, 20]" (onPageChange)="onPageChange($event)"></p-paginator>
          }
        </div>
      </div>
    </div>
  </div>
</section>
