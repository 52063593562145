import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonDialogComponent } from '../../shared/common-dialog/common-dialog.component';
import { Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { RequestMapperService } from '../../../services/request-mapper.service';
import { title } from 'node:process';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [CardModule, ButtonModule],
  templateUrl: './services.component.html',
  styleUrl: './services.component.css',
})
export class ServicesComponent {
  public _dynamicDialogRef: DynamicDialogRef | undefined;

  constructor(
    public _common: CommonService,
    public _dialogService: DialogService,
    private _router: Router
  ) {}

  navigateToBookService() {
    this._router.navigate(
      [
        `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
      ],
      {
        queryParams: { guideline: false },
      }
    );
  }
  // public openDialog(): void {
  //   let dialogWidth = '30rem';

  //   if (window.innerWidth <= 768) {
  //     dialogWidth = '90vw';
  //   } else if (window.innerWidth <= 480) {
  //     dialogWidth = '95vw';
  //   }

  //   this._dynamicDialogRef = this._dialogService.open(CommonDialogComponent, {
  //     data: {
  //       type: 'question',
  //       title:
  //         'You are about to hire a professional cleaner at an hourly rate.',
  //       // title: 'You are about to hire a professional cleaner at an hourly rate.'||'Do you need guidelines',
  //       message: 'Do you know how many hours needed for your cleaning service?',
  //       // message: 'Do you know how many hours needed for your cleaning service?'||' on the hours needed to clean your premises?',
  //       component: 'home',
  //     },
  //     modal: true,
  //     resizable: true,
  //     width: dialogWidth,
  //     height: 'auto',
  //     style: { overflow: 'hidden' },
  //     showHeader: false,
  //     closable: true,
  //     dismissableMask: true,
  //     contentStyle: { overflow: 'auto', 'padding-top': '20px' },
  //   });

  //   this._dynamicDialogRef.onClose.subscribe((result) => {
  //     if (result && result?.confirm) {
  //       this._router.navigate(
  //         [
  //           `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
  //         ],
  //         {
  //           queryParams: { guideline: true },
  //         }
  //       );
  //     } else if (result && !result?.confirm) {
  //       this._router.navigate(
  //         [
  //           `${RequestMapperService.CUSTOMER}/${RequestMapperService.SERVICE_ROUTE}/${RequestMapperService.SERVICE_BOOK}`,
  //         ],
  //         {
  //           queryParams: { guideline: false },
  //         }
  //       );
  //     }
  //     this._dynamicDialogRef?.destroy();
  //   });
  // }
}
